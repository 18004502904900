<template>
    <form class="authorization-form" @submit.prevent="submit">
        <h1 class="authorization-form__title">Авторизация</h1>
        <p class="authorization-form__subtitle">Войдите в свою учетную запись</p>

        <div class="authorization-form__form">
            <div class="authorization-form__inp-wrapper">
                <VCustomInput
                    v-model="form.login"
                    type="text"
                    placeholder="E-mail"
                    class="authorization-form__inp"
                    :error="v$.login.$error || errorInp"
                />
                <div class="authorization-form__errors" v-if="v$.login.$error">
                    <p
                        class="authorization-form__error-text"
                        v-if="v$.login.$errors[0].$validator === 'required'"
                    >
                        Заполните обязательное поле
                    </p>

                    <p
                        class="authorization-form__error-text"
                        v-if="v$.login.$errors[0].$validator === 'email'"
                    >
                        Введите правильный Email
                    </p>
                </div>
            </div>

            <div class="authorization-form__inp-wrapper">
                <VCustomInput
                    v-model="form.password"
                    type="password"
                    placeholder="Пароль"
                    class="authorization-form__inp"
                    :error="v$.password.$error || errorInp"
                />
                <div class="authorization-form__errors" v-if="v$.password.$error">
                    <p
                        class="authorization-form__error-text"
                        v-if="v$.password.$errors[0].$validator === 'required'"
                    >
                        Заполните обязательное поле
                    </p>
                </div>
                <p class="authorization-form__error-text" v-if="errorInp && !v$.$error">
                    {{ errorMessage }}
                </p>
            </div>

            <VButton
                label="Войти"
                :disabled="disabled"
                @click="submit"
                class="authorization-form__btn"
                clickEffect/>
        </div>
    </form>
    <ModalWindow v-if="showPopup" @close="showPopup = false" class="authorization-form__modal">
        <AccessClosedModal :description="errorMessage" @close="showPopup = false"/>
    </ModalWindow>
</template>

<script setup>
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {required, email, minLength} from "@vuelidate/validators";
import auth from "@/api/auth";

import AccessClosedModal from "@/components/ModalWindow/ModalBodyes/AccessClosedModal";

const store = useStore();
const router = useRouter();

const form = reactive({
    login: "",
    password: "",
});

const rules = computed(() => ({
    login: {required, email},
    password: {required},
}));
const v$ = useVuelidate(rules, form);

const errorMessage = ref("");
const errorInp = ref(false);
const showPopup = ref(false);
const disabled = ref(false);

const submit = async () => {
    const validate = await v$.value.$validate();
    if (!validate) return;
    disabled.value = true;
    try {
        const response = await auth.postLogin({
            email: form.login,
            password: form.password,
        });
        await store.dispatch("auth/auth", response);

        if (response.role === "SUPER_ADMIN") await router.push("/companies");
        if (response.role === "MAIN_HR") await router.push("/employees");
        if (response.role === "HR") await router.push("/vacancies");
        if (response.role === "DIRECTION_LEADER") await router.push("/dl-tasks");
        if (response.role === "SECURITY_SERVICE") await router.push("/sb-tasks");
        if (response.role === "APPROVING_PERSON") await router.push("/responses");
    } catch (error) {
        disabled.value = false;
        errorMessage.value = error.response.data.error_message;
        showPopup.value = error.response.data.show_popup;
        errorInp.value = !error.response.data.show_popup;
    }
};
</script>

<style scoped lang="scss">
.authorization-form {
    width: 400px;
    padding: 48px 40px;
    border-radius: 24px;
    background-color: #ffffff;
    text-align: center;
    z-index: 9;

    &__title {
        font-size: 24px;
        font-weight: 600;
    }

    &__subtitle {
        margin-top: 8px;
        color: $dark;
        font-size: 16px;
    }

    &__form {
        margin-top: 24px;
        text-align: left;
    }

    &__inp-wrapper {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__inp {
        width: 100%;
    }

    &__error-text {
        display: block;
        margin-top: 4px;
        color: $red;
        font-size: 14px;
    }

    &__btn {
        width: 100%;
        margin-top: 24px;
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
            border-radius: 24px;
        }
    }
}
</style>
